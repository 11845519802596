import React from 'react';

const ScrollDown = () => {
  const _scroll = () => {
    window.scrollTo({
      top: window.innerHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button onClick={_scroll} className="px-md cursor-pointer text-[18px]">
      {/* <span className="icon-chevron"></span> */}
      <i className="icon-chevron-s"></i>
    </button>
  );
};

export default ScrollDown;
