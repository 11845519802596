import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import Modules from '../components/modules/ModulesHome';
// import Hero from '../components/ui/Hero';
// import WeatherWidget from '../components/ui/WeatherWidget';
import clsx from 'clsx';
import ScrollDown from '../components/ui/ScrollDown';
import SliderHero from '../components/SliderHero';
import LocaleSwitcher from '../components/ui/LocaleSwitcher';

// import clsx from "clsx"

export const query = graphql`
  query HomeBySlug($slug: String!) {
    sanityHome(slug: { current: { eq: $slug } }) {
      id
      seo {
        ...seo
      }
      slug {
        current
      }
      title {
        ...localeString
      }
      # imageHero: _rawImageHero(resolveReferences: { maxDepth: 1 })
      background
      sliderHero {
        slides {
          ... on SanityImage {
            _key
            _type
            asset: _rawAsset(resolveReferences: { maxDepth: 10 })
          }
          ... on SanityVideo {
            _key
            _type
            url
          }
        }
      }
      modules {
        ... on SanityHeadlineUI {
          _key
          _type
          title
          hn
        }
        ... on SanityTexteUI {
          _key
          _type
          texte {
            ...localeBlockContent
          }
        }

        ... on SanityImageUI {
          _key
          _type
          image: _rawImage(resolveReferences: { maxDepth: 20 })
          expandable
          # image {
          #   asset {
          #     image: gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          #     metadata {
          #       dimensions {
          #         aspectRatio
          #         height
          #         width
          #       }
          #     }
          #   }
          # }
          caption {
            ...localeString
          }
        }
        ... on SanityImagesUI {
          _key
          _type
          images {
            image: _rawImage(resolveReferences: { maxDepth: 10 })
            caption: _rawCaption(resolveReferences: { maxDepth: 10 })
            expandable
          }
        }

        ... on SanitySliderUI {
          _key
          _type
          title
          images {
            ... on SanityImageCard {
              _type
              image: _rawImage(resolveReferences: { maxDepth: 10 })
              caption: _rawCaption(resolveReferences: { maxDepth: 10 })
              expandable
              title {
                ...localeString
              }
            }
            ... on SanityProjet {
              _type
              ...projetCard
            }
          }
        }
        ... on SanitySliderDyptiqueUI {
          _key
          _type
          images {
            ... on SanityImageCard {
              _type
              image: _rawImage(resolveReferences: { maxDepth: 10 })
              caption: _rawCaption(resolveReferences: { maxDepth: 10 })
              title {
                ...localeString
              }
              expandable
            }
            ... on SanityProjet {
              _type
              ...projetCard
            }
          }
        }
        ... on SanitySliderMosaicUI {
          _key
          _type
          title
          slides {
            items {
              ... on SanityTexteCard {
                _type
                color {
                  hex
                }
                texte {
                  ...localeString
                }
              }
              ... on SanityImageCard {
                _type
                image: _rawImage(resolveReferences: { maxDepth: 10 })
                imageData: image {
                  asset {
                    gatsbyImageData(width: 800)
                    metadata {
                      palette {
                        dominant {
                          background
                        }
                      }
                    }
                  }
                }
                caption: _rawCaption(resolveReferences: { maxDepth: 10 })
                expandable
              }
            }
          }
        }
      }
    }
  }
`;

const Home = ({ pageContext, data }) => {
  // console.log(pageContext);
  const { seo, slug, background, sliderHero, modules } = data.sanityHome;
  const hasHero = sliderHero;
  return (
    <div className={clsx('main-area', !hasHero ? 'pt-header-height' : '')}>
      {seo && (
        <Seo
          metaTitle={seo.metaTitle}
          metaDescription={seo.metaDescription}
          metaImage={seo.metaImage?.asset.url}
          template={`template-page page-${slug.current} home ${
            background ? `bg-${background}` : ''
          }`}
          page={false}
        />
      )}

      {sliderHero && (
        <div className="mb-_md md:mb-md">
          <SliderHero input={sliderHero} />
          <div className="absolute z-10 w-full">
            <div className="container h-full ">
              {/* <div className="weather absolute bottom-_sm md:bottom-md text-sm font-secondary text-secondary bg-primary-20 pill has-backdrop-filter">
              <WeatherWidget />
            </div> */}
              <div className="absolute bottom-_sm md:bottom-md text-sm font-secondary text-secondary">
                <LocaleSwitcher />
              </div>
              <div className="absolute hidden-sm bottom-md left-1/2 -translate-x-1/2 text-secondary text-sm z-10">
                <ScrollDown />
              </div>
            </div>
          </div>
        </div>
      )}

      {modules && <Modules input={modules} />}
    </div>
  );
};

export default Home;
